<template>
  <div>
    <h1>Thèse</h1>
    <el-row style="width: 820px" v-loading="theseLoadStatus == 1">
      <el-col :span="24">
        <form-these :ismeddetail="ismeddetail" ref="formTh"> </form-these>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormThese from "@/components/rh/ui/form/FormThese";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-these": FormThese },

  data() {
    return {
      loadCount: 0,
    };
  },
  props: {
    ismeddetail: Boolean,
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("th/loadThese", this.idTh);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idTh() {
      // récupère le id de la these dans l'url
      return String(this.$route.params.idth);
    },
    theseLoadStatus() {
      return this.$store.state.th.theseLoadStatus;
    },
    theseUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.th.theseUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.theseLoadStatus == 2) {
        // charge les données de la thèse
        const th = this.$store.state.th.these;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.thLoaded = JSON.stringify(th);
      } else if (this.theseLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formTh.$refs.formThese.validate((valid) => {
        if (valid) {
          let th = this.$store.state.th.these;

          // enlever les espaces avant et après dans les string
          trimStringInObj(th);

          const thInBD = this.$store.getters["th/TheseInBD"];
          const id = this.idTh;
          const doublon =
            thInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == th.id_med &&
                m[2] == th.id_fac &&
                (!m[3] && !th.annee
                  ? m[4] == th.id_statut
                  : Number(m[3]) && Number(th.annee)
                  ? m[3] == th.annee
                  : false)
            ).length > 0;
          // prépare les données pour les comparer avec les données avant modification et pour l'enregistrement
          const thdataStr = JSON.stringify(th);

          // vérifie si les données ont été modifiées
          if (localStorage.thLoaded == thdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si une these idendique existe déjà pour le médecin
          } else if (doublon) {
            const alertMsg = th.annee
              ? "Ce médecin a déjà une thèse enregistrée dans la même faculté pour la même année."
              : "Ce médecin a déjà une thèse enregistrée dans la même faculté avec le même statut.";
            this.$alert(alertMsg, "Attention", {
              confirmButtonText: "Fermer",
              dangerouslyUseHTMLString: true,
            });
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("th/updateThese", th);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const th = this.$store.state.th.these;
      const thStr = JSON.stringify(th);
      if (localStorage.thLoaded != thStr) {
        // si les données ont été modifiées
        let dataObj = JSON.parse(localStorage.thLoaded);
        this.$store.commit("th/setThese", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modification
      const th = this.$store.state.th.these;
      const thStr = JSON.stringify(th);

      if (localStorage.thLoaded == thStr) {
        // si pas de modifications
        // retourne à la liste des formations pré-graduées
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des fthèses
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    theseLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    theseUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.theseUpdateStatus == 2) {
        const th = this.$store.state.th.these;
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.thLoaded = JSON.stringify(th);
      } else if (this.theseUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
