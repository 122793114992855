<template>
  <el-main class="rh-medform">
    <form-theseedit :ismeddetail="true" :routetolist="thRouteToList" />
  </el-main>
</template>
<script>
import FormTheseedit from "@/components/rh/formulaires/FormTheseedit";

export default {
  components: { "form-theseedit": FormTheseedit },
  computed: {
    thRouteToList() {
      return {
        name: "med_theses",
        params: {
          idmed: this.$route.params.idmed,
        },
      };
    },
  },
};
</script>
