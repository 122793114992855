<template>
  <div class="app-form">
    <el-form
      ref="formFaculte"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <!-- Pays -->
      <el-form-item label="Pays" prop="id_pays">
        <el-select
          v-model="form.id_pays"
          clearable
          filterable
          placeholder="Choisissez un pays"
          :disabled="paysdesactive"
        >
          <el-option
            v-for="item in paysOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- Faculté -->
      <el-form-item label="Faculté" prop="fac">
        <el-input v-model="form.fac" style="width: 300px"> </el-input>
      </el-form-item>
      <!-- Date maj -->
      <p class="updated-at" v-show="form.updated_at">
        Dernière maj :
        {{ form.updated_at }}
      </p>
      <!-- -->
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rules: {
        id_pays: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        fac: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    paysdesactive: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.fac.faculte;
      },
      set(form) {
        this.$store.commit("fac/setFaculte", form);
      },
    },
    paysOptions() {
      return this.$store.getters["paysl/paysSelectSansEu"];
    },
  },
};
</script>
