<template>
  <div class="app-form">
    <el-form
      ref="formThese"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <!-- Médecin -->
      <el-form-item label="Médecin" prop="id_med" v-show="!ismeddetail">
        <el-select
          v-model="form.id_med"
          clearable
          filterable
          placeholder="Choisissez un médecin"
        >
          <el-option
            v-for="item in medecinOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- Pays -->
      <el-form-item label="Pays" prop="id_pays">
        <el-select
          v-model="pays"
          clearable
          filterable
          placeholder="Choisissez un pays"
          @change="resetFac"
        >
          <el-option
            v-for="item in paysOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- Faculté -->
      <el-form-item label="Faculté" prop="id_fac">
        <el-select
          ref="fac"
          v-model="form.id_fac"
          :disabled="desactive"
          clearable
          filterable
          placeholder="Choisissez une faculte"
          style="width: 300px"
        >
          <el-option
            v-for="item in facOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
          <el-option value="">
            <el-button type="text" @click="addFac">
              <i class="el-icon-circle-plus-outline"></i
              ><i> Ajouter une faculté</i>
            </el-button>
          </el-option>
        </el-select>
      </el-form-item>
      <!-- Titre -->
      <el-form-item label="Titre" prop="titre">
        <el-input v-model="form.titre" style="width: 500px"> </el-input>
      </el-form-item>
      <!-- Statut -->
      <el-form-item prop="id_statut" label="Statut">
        <el-radio-group
          v-model="form.id_statut"
          size="small"
          style="width: 280px"
          @change="anneeReset"
        >
          <el-radio-button :label="1">prévue</el-radio-button>
          <el-radio-button :label="2">en cours</el-radio-button>
          <el-radio-button :label="3">terminée</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!-- Année -->
      <el-form-item label="Année" prop="annee" v-show="form.id_statut == 3">
        <el-select
          ref="annee"
          v-model="form.annee"
          clearable
          filterable
          placeholder="Choisissez une annnée"
        >
          <el-option
            v-for="item in anneeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- -->
      <p class="updated-at" v-show="form.updated_at">
        Dernière maj :
        {{ form.updated_at }}
      </p>
    </el-form>
    <!-- boîte de dialogue pour ajouter une faculté -->
    <dialogform-facultecreate :paysdesactive="true" />
  </div>
</template>
<script>
import DialogformFacultecreate from "@/components/rh/dialogbox/DialogformFacultecreate";

export default {
  components: { "dialogform-facultecreate": DialogformFacultecreate },

  data() {
    var valideAnnee = (rule, value, callback) => {
      if (this.form.id_statut && this.form.id_statut == 3) {
        // si statut = terminée
        if (value) {
          callback();
        } else {
          callback(new Error("Ce champ est obligatoire"));
        }
      } else {
        callback();
      }
    };
    return {
      pays: null,
      desactive: true,
      rules: {
        id_med: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        //id_fac: [{required: true, message: 'Ce champ est obligatoire', trigger: 'change'},],
        //titre: [{required: true, message: 'Ce champ est obligatoire', trigger: 'blur'},],
        id_statut: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        annee: [{ required: true, validator: valideAnnee, trigger: "change" }],
      },
    };
  },
  props: {
    ismeddetail: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.th.these;
      },
      set(form) {
        this.$store.commit("th/setThese", form);
      },
    },
    dialogShowFacCreate: {
      get() {
        return this.$store.state.comp.dialogShowFacCreate;
      },
      set(dialogShowFacCreate) {
        this.$store.commit("comp/setDialogShowFacCreate", dialogShowFacCreate);
      },
    },
    medecinOptions() {
      return this.$store.getters["medl/medecinSelect"];
    },
    paysOptions() {
      return this.$store.getters["paysl/paysSelectSansEu"];
    },
    facOptions() {
      const facs = this.$store.getters["fac/facSelect"];
      return this.pays ? facs.filter((o) => o.pays == this.pays) : [];
    },
    faculteCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fac.faculteCreateStatus;
    },
    anneeOptions() {
      return this.$store.getters["th/optAnnee"];
    },
    alldataLoaded() {
      return (
        this.$store.state.th.theseLoadStatus == 2 &&
        this.$store.state.paysl.paysLoadStatus == 2 &&
        this.$store.state.fac.facultesLoadStatus == 2
      );
    },
    idFac() {
      return this.form.id_fac;
    },
  },

  methods: {
    setPays() {
      const idFac = this.form.id_fac;
      if (idFac) {
        const facs = this.$store.getters["fac/facSelect"];
        // récupère le id du pays
        this.pays = facs.filter((o) => o.value == idFac)[0].pays;
      }
      // active 'Faculté' si un pays est choisi
      this.desactive = this.pays ? false : true;
    },
    resetFac() {
      const opt = this.facOptions;
      // si une seule option, la sélectionne
      const fac = opt.length == 1 ? opt[0].value : null;
      // vide le champs 'Faculté' sur changement de 'pays'
      this.$refs.fac.$emit("input", fac);
      // active 'Faculté' si un pays est choisi
      this.desactive = this.pays ? false : true;
    },
    addFac() {
      this.dialogShowFacCreate = true;
      this.$store.commit("fac/resetFaculte", this.pays);
    },
    anneeReset() {
      // supprime année sur changement de statut
      this.form.annee = "";
    },
  },
  watch: {
    alldataLoaded() {
      if (this.alldataLoaded) {
        this.setPays();
      }
    },
    idFac() {
      // maj le pays sélectionné quand annule changement de pays
      this.setPays();
    },
    faculteCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      // et l'affichage de la nouvelle faculté dans le dropdown
      if (this.faculteCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La faculté a été ajoutée à la base de donnée",
          type: "success",
        });
        // sélectionne la faculté qui viens d'être créée
        this.form.id_fac = this.$store.state.fac.faculteLastCreatedId;
      } else if (this.faculteCreateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
