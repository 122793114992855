<template>
  <el-dialog
    title="Ajouter une faculté"
    :visible.sync="dialogShowFacCreate"
    width="460px"
  >
    <form-faculte ref="formFac" :paysdesactive="paysdesactive" />
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" type="primary" @click="facOnSubmit"
        >Enregistrer</el-button
      >
      <el-button size="mini" @click="facOnCancel">Annuler</el-button>
    </span>
  </el-dialog>
</template>
<script>
import FormFaculte from "@/components/rh/ui/form/FormFaculte";
import { strNormalize } from "@/fonctions";
export default {
  components: { "form-faculte": FormFaculte },
  data() {
    return {};
  },
  props: {
    paysdesactive: Boolean,
  },
  computed: {
    dialogShowFacCreate: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.comp.dialogShowFacCreate;
      },
      set(dialogShowFacCreate) {
        this.$store.commit("comp/setDialogShowFacCreate", dialogShowFacCreate);
      },
    },
  },
  methods: {
    facOnSubmit() {
      this.$refs.formFac.$refs.formFaculte.validate((valid) => {
        if (valid) {
          // récupère les données de la boîte de dialogue
          const bd = this.$store.state.fac.faculte;
          // enlever les espaces avant et après dans le nom de la nouvelle fac
          bd.fac = bd.fac.trim();

          // vérifie si la faculté est  déjà dans la bd
          const FacsInBD = this.$store.getters["fac/facultesInBD"];
          // nom de la fac en minuscule et sans espace et sans accents pour la comparaison
          const facNormalize = strNormalize(bd.fac);

          if (FacsInBD.filter((m) => m[2] == facNormalize).length > 0) {
            this.$alert(
              "Cette faculté est déjà enregistrée dans la base de données !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            //crée un nouveau enregistrement
            this.$store.dispatch("fac/createFaculte", {
              id_pays: bd.id_pays,
              fac: bd.fac,
            });
            // ferme la boîte de dialogue
            this.dialogShowFacCreate = false;
          }
        } else {
          return false;
        }
      });
    },
    facOnCancel() {
      // ferme la boîte de dialogue
      this.dialogShowFacCreate = false;
      // reset le form
      this.$refs.formFac.$refs.formFaculte.resetFields();
    },
  },
};
</script>
